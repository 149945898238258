<template>
<div class="">
    <div class="thank-you-info">
        <b-container>
            <b-row class="justify-content-center">
                <b-col md="8" class="text-center">
                    <div class="thank-you-card">
                        <div class="thank-you-icon mb-1">
                            <feather-icon class="" icon="CheckIcon" size="36" />
                        </div>
                        <h2>Thank You!</h2>
                        <p class="lead mb-0"> Thank you for your purchase. Order details and invoice are sent to your email address.&#128522;</p>
                    </div>
                </b-col>
            </b-row>

            <b-row class="justify-content-center">

                <b-col md="8" class="">
                    <b-row>

                        <b-col md="6" class="mt-2 mt-md-0">

                        </b-col>
                    </b-row>
                </b-col>
            </b-row>

        </b-container>
    </div>
</div>
</template>

<script>
import {
    BCard,
    BCardText,
    BButton,
    BContainer,
    BRow,
    BCol,
    BImg,
    BCardBody,
    BCardTitle,
    BCardSubTitle,
    BBadge,
    BLink,
    BFormGroup,
    BFormInput,
    BInputGroup,
    BInputGroupAppend,
    BFormSelect,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'

export default {
    components: {
        BContainer,
        BRow,
        BCol,
        BCard,
        BCardText,
        BButton,
        BCardBody,
        BCardTitle,
        BCardSubTitle,
        BImg,
        BBadge,
        BLink,
        BFormGroup,
        BFormInput,
        BInputGroup,
        BInputGroupAppend,
        BFormSelect,
    },
    directives: {
        Ripple,
    },
    data() {
        return {

            product_category_name: '',
            product_type_name: '',
            productURL: ''
        }
    },
    created() {
        this.getProductDetails(this.$route.params.id)
    },

    methods: {
        async getProductDetails(value) {
            let tenant_id = JSON.parse(localStorage.getItem("domain_Data")).tenant_id;;

                var myHeaders = new Headers();
             
                let crm_id = JSON.parse(localStorage.getItem("userdetails")).user_id;
                const api_key = JSON.parse(localStorage.getItem("domain_Data")).api_key;
                myHeaders.append('x-api-key', api_key);
                myHeaders.append("Content-Type", "application/x-www-form-urlencoded");
                var urlencoded = new URLSearchParams();

                urlencoded.append("tenant_id", tenant_id);
                urlencoded.append("product_id", this.$route.params.product_id);
                urlencoded.append("order_id", this.$route.params.order_id);
                urlencoded.append("item_type", "product");
                urlencoded.append("user_id", crm_id);
                var requestOptions = {
                    method: "POST",
                    headers: myHeaders,
                    body: urlencoded,
                    redirect: "follow",
                };

                const rawResponse = await fetch(
                    process.env.VUE_APP_BASE_URL + "ecommerce/getOrderDetailsById",
                    requestOptions
                );

                const response = await rawResponse.json();

                const orderDetails = response.data[0]

                this.productURL = orderDetails.product_image_url
                this.product_type_name = orderDetails.product_type_name
                this.product_category_name = orderDetails.product_category_name

            
        },
    }
}
</script>

<style lang="scss">

</style>
